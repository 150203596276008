




import { Component, Vue, Watch, Provide } from 'vue-property-decorator';
import FlowViewer from './flow-viewer.vue';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';

@Component({
  name: 'FlowDone',
  components: { FlowViewer }
})
export default class FlowStart extends Vue {
  @Provide() flowGraphDisable: string = 'top'; //属性面板的禁用 top--表示路由级别禁用（级别最低）； components--表示组件级别禁用（级别一般）；end--表示最底层禁用（级别最高）

  operation: string = 'task_end';
  get _taskDataId() {
    return this.$route.query.id;
  }

  private created(): void {
    if (this.$route.query.token) {
      StorageUtil.setSessionStorage('token', this.$route.query.token);
    }
  }
}
